import React, { useState } from "react"
import styled from "styled-components"
import { FullSplit, Title, Copy, Cover, CoverImg } from "../../pages/activities"
import { MainButton } from "../../styles/buttons"
import { Lightbox } from "react-modal-image"

const clubRates = require("../../images/golf-club.jpg")

const JoinClub = ({ title, copy, image }) => {
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <FullSplit>
            <ClubContent>
                <Title>{title}</Title>
                <Copy>{copy}</Copy>
                <MainButton onClick={() => setModalOpen(true)}>
                    View Membership Packages
                </MainButton>
            </ClubContent>
            <Cover style={{ height: "100%" }}>
                <CoverImg
                    style={{
                        height: "100%",
                        minHeight: "60vh",
                        objectFit: "cover",
                    }}
                    src={image}
                />
            </Cover>
            {modalOpen && (
                <Lightbox
                    medium={clubRates}
                    large={clubRates}
                    // alt="Golf Membership Packages"
                    onClose={() => setModalOpen(false)}
                    hideDownload={true}
                    showRotate={false}
                />
            )}
        </FullSplit>
    )
}

export default JoinClub

const ClubContent = styled.div`
    padding: 50px;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding: 20px;
        margin-bottom: 50px;
    }
`
