import React from "react"
import styled from "styled-components"
import { MaxWidthContainer } from "../layout"

const Heading = styled.h5`
    font-size: 18px;
`
const Copy = styled.p`
    font-size: 18px;
`

const FactItem = ({ heading, copy }) => (
    <div style={{ maxWidth: 300 }}>
        <Heading>{heading}</Heading>
        <Copy>{copy}</Copy>
    </div>
)

const Container = styled(MaxWidthContainer)`
    padding: 12vh 20px;
`
const Title = styled.h2`
    font-size: 55px;
    text-transform: uppercase;
    margin-bottom: 70px;
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    @media (max-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const CourseFacts = () => {
    return (
        <Container>
            <Title>Course quick facts</Title>
            <Grid>
                {facts.map((fact, index) => (
                    <FactItem key={index} heading={fact.title} copy={fact.copy} />
                ))}
            </Grid>
        </Container>
    )
}

export default CourseFacts

const facts = [
    {
        title: "Par 72 Course",
        copy: "Par 72 Course 6,793 yards from the back tees.",
    },
    {
        title: "Five Par-3s",
        copy:
            "No. 7 is 186 yards, which can be a real challenge with water on the left of the greens and out of bounds on the right. Water comes into play on four of the holes.",
    },
    {
        title: "Course Records",
        copy:
            " Men’s is 65, held by British pro, Roger Chapman. Ladies’ is 69, held by Julie Greenhalgh.",
    },
    {
        title: "Five Par-5s",
        copy:
            "Ranging in distance from an easy 466 yards to a testing 535 yards.",
    },
    {
        title: "Eight Par-4s",
        copy: "Five on the front 9 and three on the back.",
    },
]
