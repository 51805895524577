import React, { useState } from "react"
import styled from "styled-components"
import { AltButton } from "../../styles/buttons"
import GolfRates from "./golf-rates"

const Container = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12vh 20px;
    color: white;
    position: relative;
    min-height: 80vh;
    overflow: hidden;
    text-align: center;
`
const Cover = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
`
const ColoredCover = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background: rgba(8, 66, 77, 0.95);
`
const Title = styled.h1`
    z-index: 1;
    font-size: 80px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
`
const Copy = styled.p`
    z-index: 1;
    max-width: 600px;
    line-height: 1.4;
    margin: 0 auto;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    margin-bottom: 65px;
`

const PricingBanner = ({ title, copy }) => {
    const [showingPricing, setShowingPricing] = useState(false)
    return (
        <Container>
            <Cover src={require("../../images/golf.jpg")} />
            {showingPricing && <ColoredCover />}
            {showingPricing ? (
                <GolfRates showing={setShowingPricing} />
            ) : (
                <>
                    <Title>{title}</Title>
                    <Copy>{copy}</Copy>
                    <AltButton
                        onClick={() => setShowingPricing(!showingPricing)}
                    >
                        View Pricing
                    </AltButton>
                </>
            )}
        </Container>
    )
}

export default PricingBanner
