import React from "react"
import styled from "styled-components"
import { colors } from "../../styles/colors"
import { CloseButton } from "../forms/reusables"

const Container = styled.div`
    z-index: 1;
`
const Content = styled.div`
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    align-items: center;

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`
const Table = styled.table`
    font-family: "komet";
    font-size: 16px;
    margin: 0;

    @media (max-width: 700px) {
        order: 2;
    }
    @media (max-width: 550px) {
        font-size: 14px;
    }
`
const TDetails = styled.td`
    text-transform: uppercase;
    padding-top: 7px;
    padding-bottom: 7px;
    border: none;
    font-weight: ${props => (props.title ? 700 : 300)};
    color: ${props => (props.title ? colors.primary : "white")};
`
const Copy = styled.p`
    margin: 0;
    text-align: left;

    @media (max-width: 700px) {
        margin-bottom: 30px;
    }
`

const GolfRates = ({ showing }) => {
    return (
        <Container>
            <CloseButton absolute={true} handleClose={showing} />
            <Content>
                <Table>
                    <thead>
                        <tr>
                            <th> </th>
                            <TDetails title>Resort Guests</TDetails>
                            <TDetails title>Non Resident Visitors</TDetails>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <TDetails title>Green Fees</TDetails>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <TDetails>9 Holes</TDetails>
                            <TDetails>45</TDetails>
                            <TDetails>54</TDetails>
                        </tr>
                        <tr>
                            <TDetails>18 Holes</TDetails>
                            <TDetails>65</TDetails>
                            <TDetails>78</TDetails>
                        </tr>
                        <tr>
                            <TDetails>Weekly Unlimited</TDetails>
                            <TDetails>420</TDetails>
                            <TDetails>504</TDetails>
                        </tr>
                        <tr>
                            <TDetails title>Club Rentals</TDetails>
                            <TDetails></TDetails>
                            <TDetails></TDetails>
                        </tr>
                        <tr>
                            <TDetails>9 Holes</TDetails>
                            <TDetails>50</TDetails>
                            <TDetails>50</TDetails>
                        </tr>
                        <tr>
                            <TDetails>18 Holes</TDetails>
                            <TDetails>50</TDetails>
                            <TDetails>50</TDetails>
                        </tr>
                        <tr>
                            <TDetails title>Caddy Fees</TDetails>
                            <TDetails></TDetails>
                            <TDetails></TDetails>
                        </tr>
                        <tr>
                            <TDetails>9 Holes</TDetails>
                            <TDetails>30</TDetails>
                            <TDetails>30</TDetails>
                        </tr>
                        <tr>
                            <TDetails>18 Holes</TDetails>
                            <TDetails>35</TDetails>
                            <TDetails>35</TDetails>
                        </tr>
                    </tbody>
                </Table>
                <div>
                    <Copy>
                        These rates are in US dollars, and are inclusive of
                        12.5% V.A.T.
                        <br />
                        <br />
                        Green Fees are inclusive of shared carts.
                        <br />
                        <br />
                        Rates are subject to change without notice.
                    </Copy>
                </div>
            </Content>
        </Container>
    )
}

export default GolfRates
