import React from "react"
import styled from "styled-components"
import { MaxWidthContainer } from "../layout"

const Container = styled(MaxWidthContainer)`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 30px;
    margin-bottom: 12vh;
    width: 100%;
    padding: 15px;

    @media (max-width: 550px) {
        grid-template-columns: repeat(4, 1fr);
    }
`
const Award = styled.img`
    margin: 0;
`

const AwardList = ({ awards }) => {
    return (
        <Container>
            {awards.map((award, index) => (
                <Award key={index} src={award.image.sourceUrl} />
            ))}
        </Container>
    )
}

export default AwardList
