import React from "react"
import {
    Content,
    Copy,
    Cover,
    CoverImg,
    Split,
    Title,
} from "../../pages/activities"
import { colors } from "../../styles/colors"

const AbovePar = ({ title, copy, image }) => {
    return (
        <Split>
            <Content>
                <Copy style={{ fontWeight: 700, color: colors.primary }}>
                    The Mount Irvine Golf Course is currently closed for
                    renovation.
                </Copy>
                <Title>{title}</Title>
                <Copy>{copy}</Copy>
            </Content>
            <Cover>
                <CoverImg src={image} />
            </Cover>
        </Split>
    )
}

export default AbovePar
