import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import AbovePar from "../components/golf/above-par"
import AwardList from "../components/golf/award-list"
import CourseFacts from "../components/golf/course-facts"
import JoinClub from "../components/golf/join-club"
import PricingBanner from "../components/golf/pricing-banner"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import TitleBlock from "../components/title-block"

const Container = styled.section``

const GolfPage = ({ data }) => {
    const pageData = data.wpPage.golf_content
    const {
        title,
        subtitle,
        introImage,
        pricingTitle,
        pricingCopy,
        joinClubTitle,
        joinClubDescription,
        joinClubImage,
        awardsTitle,
        awardsCopy,
        awards,
    } = pageData
    return (
        <Layout>
            <SEO title="Golf" />
            <PageHeader title="The Golf Course" />
            <Container>
                <AbovePar
                    title={title}
                    copy={subtitle}
                    image={introImage.sourceUrl}
                />
                <PricingBanner title={pricingTitle} copy={pricingCopy} />
                <CourseFacts />
                <JoinClub
                    title={joinClubTitle}
                    copy={joinClubDescription}
                    image={joinClubImage.sourceUrl}
                />
                <TitleBlock title={awardsTitle} copy={awardsCopy} />
                <AwardList awards={awards} />
            </Container>
        </Layout>
    )
}

export default GolfPage

export const GolfQuery = graphql`
    query golfQuery {
        wpPage(title: { eq: "Golf" }) {
            golf_content {
                awards {
                    image {
                        sourceUrl
                    }
                }
                awardsCopy
                awardsTitle
                introImage {
                    sourceUrl
                }
                joinClubImage {
                    sourceUrl
                }
                joinClubDescription
                joinClubTitle
                pricingCopy
                pricingTitle
                title
                subtitle
            }
        }
    }
`
